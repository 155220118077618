import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { PairToken } from '@app/types/pool';
import { Token } from '@app/types/token';
import { mapPairTokenToToken } from '@app/hooks/swap/helpers';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { uniqWith } from 'lodash';
import { useConfig } from '@app/config';
import { useChainId } from '@thirdweb-dev/react';

type QueryParams = {
  q: string;
  showAll: boolean;
  isV3?: boolean;
};

const fetcher = (baseUrl: string, params: QueryParams) => {
  return request<{ tokens: PairToken[] }>(
    `${baseUrl}`,
    gql`
      query {
        tokens(
          first: 1000
          where: {
            or: [
              { symbol_contains_nocase: "${params.q}" }
              { name_contains_nocase: "${params.q}" }
              { id: "${params.q}" }
            ]
          }
        ) {
          id
          totalSupply
          symbol
          name
          decimals
        }
      }
    `
  );
};

export const useTokens = (
  params: QueryParams,
  options: UseQueryOptions<Token[]> = {}
) => {
  const config = useConfig();
  const chainId = useChainId();

  return useQuery<Token[]>(
    [QueryKeys.TOKENS, params, chainId],
    async () => {
      const response = await fetcher(
        (params.isV3
          ? config?.URLS.subgraphUrlV3
          : config?.URLS.subgraphUrl) as string,
        params
      );

      const _tokens = uniqWith(
        [
          ...getTokensWhitelist(config).map(item => ({
            ...item,
            whitelisted: true
          })),
          ...response.tokens.map(t => mapPairTokenToToken(t, config))
        ],
        (a, b) =>
          a.contractAddress.toLowerCase() === b.contractAddress.toLowerCase()
      ).filter(item => {
        if (!params.q) {
          return true;
        }

        if (item.symbol.toLowerCase().includes(params.q.toLowerCase())) {
          return true;
        }

        if (item.name.toLowerCase().includes(params.q.toLowerCase())) {
          return true;
        }

        if (item.contractAddress.toLowerCase() === params.q.toLowerCase()) {
          return true;
        }

        return false;
      });

      const wlTokensIds = getTokensWhitelist(config).map(t =>
        t.contractAddress.toLowerCase()
      );

      const wlTokensSymbols = getTokensWhitelist(config).map(t =>
        t.symbol.toLowerCase()
      );

      return _tokens
        .filter(item => {
          if (!item.symbol) {
            return false;
          }

          // filter fake stable coins like USDT, usdc etc.
          if (wlTokensSymbols.includes(item.symbol.toLowerCase())) {
            return wlTokensIds.includes(item.contractAddress.toLowerCase());
          }

          return true;
        })
        .filter(item => {
          if (
            item.contractAddress.toLowerCase() ===
            config?.CONTRACTS.KIM?.toLowerCase()
          ) {
            return true;
          }

          if (
            item.contractAddress.toLowerCase() ===
            config?.CONTRACTS.X_KIM?.toLowerCase()
          ) {
            return false;
          }

          if (
            item.contractAddress.toLowerCase() ===
            '0xDfc7C877a950e49D2610114102175A06C2e3167a'.toLowerCase()
          ) {
            return true;
          }

          // Filter fake kim/mode tokens
          if (
            item.symbol.toLowerCase().startsWith('kim') ||
            item.symbol.toLowerCase().startsWith('mode')
          ) {
            return false;
          }

          // Filter unverified tokens
          if (!params.showAll) {
            return wlTokensIds.includes(item.contractAddress.toLowerCase());
          }

          return true;
        });
      // .sort((a, b) => {
      //   if (wlTokensIds.includes(a.contractAddress.toLowerCase())) {
      //     return -1;
      //   } else {
      //     return 1;
      //   }
      // });
    },
    {
      // staleTime: 60000,
      enabled: !!config,
      ...options
    }
  );
};
