import { Button, ButtonProps } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  disabled?: boolean;
  isLoading: boolean;
  onClick?: () => void;
  label: string;
  loadingText?: string;
  maxWidth?: string | number;
}

export const SubmitButton: FC<Props> = ({
  isLoading,
  onClick,
  label,
  loadingText,
  disabled,
  maxWidth,
  ...rest
}) => {
  return (
    <Button
      w="100%"
      position="relative"
      isDisabled={disabled}
      isLoading={isLoading}
      loadingText={loadingText}
      mt="16px"
      bg={disabled ? 'neutral.700' : 'orange'}
      py="10px"
      px="20px"
      fontSize="14px"
      border="1px solid transparent"
      borderRadius="8px"
      onClick={onClick}
      color={disabled ? 'neutral.400' : 'white'}
      transition="all 0.2s ease"
      _hover={
        disabled
          ? {
              background: 'neutral.700',
              color: 'neutral.400',
              cursor: 'default'
            }
          : { background: 'orange', color: 'white', filter: 'brightness(120%)' }
      }
      sx={{
        maxWidth: ['unset', 'unset', maxWidth]
      }}
      {...rest}
    >
      {label}
    </Button>
  );
};
