import {
  Box,
  Button,
  Center,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { Icon } from '@app/components/Icon';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';

interface Props {
  status?: 'success' | 'error';
  title?: string;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  txHash?: string;
}

export const TransactionResultModal: FC<Props> = ({
  isOpen,
  onClose,
  status,
  title,
  message,
  txHash
}) => {
  const router = useRouter();

  const { explorer } = useChainExplorer();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '48px 32px' }}>
        <VStack w="100%" gap={0}>
          <Center w="140px" h="140px" borderRadius="50%" bg={status}>
            <Center
              w="93px"
              h="93px"
              borderRadius="50%"
              bg={status === 'success' ? '#2f6930' : '#9a3b18'}
              sx={{
                filter:
                  'drop-shadow(0px 4.320987701416016px 12.098764419555664px rgba(0, 0, 0, 0.71))'
              }}
            >
              <Icon
                name={status === 'success' ? 'check' : 'close'}
                style={{ width: 96, height: 96 }}
              />
            </Center>
          </Center>
          <Box mt="48px" w="100%">
            <Text
              textAlign="center"
              w="100%"
              color="white"
              fontSize="26px"
              fontWeight={500}
              lineHeight="32px"
            >
              {title}
            </Text>
            <Text
              w="100%"
              color="whiteOpacity.50"
              fontSize="18px"
              lineHeight="20px"
              fontWeight={400}
              mt="12px"
              textAlign="center"
            >
              {message}
            </Text>
          </Box>
        </VStack>
        <ModalFooter justifyContent="space-between" gap="16px" mt="43px">
          <Button
            w="100%"
            bg="gray.7"
            py="10px"
            px="20px"
            borderRadius="12px"
            onClick={async () => {
              if (txHash) {
                window.open(`${explorer?.url}/tx/${txHash}`);
              } else {
                router.push('/positions');
              }
            }}
            color="white"
            _hover={{ filter: 'brightness(120%)' }}
          >
            <Text
              fontSize="16px"
              fontFamily="'Inter', sans-serif"
              fontWeight="500"
              lineHeight="20px"
            >
              {txHash ? 'View on explorer' : 'Your positions'}
            </Text>
          </Button>
          <Button
            w="100%"
            bg="orange"
            py="10px"
            px="20px"
            borderRadius="12px"
            onClick={async () => {
              onClose();
            }}
            color="white"
            _hover={{ filter: 'brightness(120%)' }}
          >
            <Text
              fontSize="16px"
              fontFamily="'Inter', sans-serif"
              fontWeight="500"
              lineHeight="20px"
            >
              {status === 'success' ? 'Done' : 'Go back'}
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
