import {
  ADDRESS_ZERO,
  Currency,
  ExtendedNative,
  Token
} from '@cryptoalgebra/kim-sdk';
import {
  DEFAULT_NATIVE_NAME,
  DEFAULT_NATIVE_SYMBOL
} from '@app/constants/common';
import { Token as KimToken } from 'src/types/token';
import { NativeCurrency } from '@uniswap/sdk-core';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { NEW_WEETH_TOKEN, OLD_WEETH_TOKEN } from '@app/constants/tokens';
import { supportedChainsIds } from '@app/context/AppContextWrapper';

export function getCurrencyFromKimToken(token: KimToken, chainId: number) {
  if (!supportedChainsIds.includes(chainId)) {
    return;
  }

  if (
    token.contractAddress === ADDRESS_ZERO ||
    token.contractAddress === NATIVE_TOKEN_ADDRESS
  ) {
    return ExtendedNative.onChain(
      chainId,
      DEFAULT_NATIVE_SYMBOL,
      DEFAULT_NATIVE_NAME
    );
  }

  const { symbol, name, decimals } = token;

  return new Token(chainId, token.contractAddress, +decimals, symbol, name);
}

export function getKimTokenFromCurrency(currency: Currency | null | undefined) {
  if (!currency) {
    return null;
  }

  if (currency instanceof NativeCurrency) {
    return {
      contractAddress: ADDRESS_ZERO,
      symbol: DEFAULT_NATIVE_SYMBOL,
      name: DEFAULT_NATIVE_NAME,
      decimals: 18,
      logoUri: ''
    };
  }

  return {
    contractAddress: (currency as Token).address,
    symbol: currency.symbol ?? '',
    name: currency.name ?? '',
    decimals: currency.decimals,
    logoUri: ''
  };
}

export function getCurrencySymbol(token: KimToken | null) {
  if (!token) {
    return undefined;
  }

  if (token.contractAddress?.toLowerCase() === OLD_WEETH_TOKEN) {
    return '(OLD) weETH';
  }

  if (token.contractAddress?.toLowerCase() === NEW_WEETH_TOKEN) {
    return '(NEW) weETH.mode';
  }

  return token.symbol;
}

export function getCurrencyFromToken(
  token: Token | undefined,
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
) {
  if (token?.address === currencyA?.wrapped.address) {
    return currencyA;
  }

  if (token?.address === currencyB?.wrapped.address) {
    return currencyB;
  }

  return null;
}
